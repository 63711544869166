import React, { useState } from "react";
import { marked } from "marked";
import hljs from "highlight.js";

import styles from "./Message.module.css";

const renderer = new marked.Renderer();

renderer.code = (code, language) => {
    const validLang = hljs.getLanguage(language) ? language : "plaintext";
    return `<pre><code class="hljs ${validLang}">${
        hljs.highlight(validLang, code).value
    }</code></pre>`;
};

marked.setOptions({
    renderer,
    highlight: () => {},
});

const themes = {
    asuka: {
        personaMessage: {
            backgroundColor: "#DCF8C6",
        },
        userMessage: {
            backgroundColor: "#D6EAF8",
        },
    },
    grog: {
        personaMessage: {
            backgroundColor: "#708238",
            borderColor: "#556B2F",
        },
        userMessage: {
            backgroundColor: "#C19A6B",
            borderColor: "#8B5A2B",
        },
    },
    hermione: {
        personaMessage: {
            backgroundColor: "#DCF8C6",
            borderColor: "#000",
            boxShadow: "2px 2px 2px rgb(0,0,0, 70%)",
        },
        userMessage: {
            backgroundColor: "#D6EAF8",
            borderColor: "#000",
            boxShadow: "2px 2px 2px rgb(0,0,0, 70%)",
            marginRight: "6px",
        },
    },
    custom: {
        personaMessage: {},
        userMessage: {},
    },
};

const MarkdownRenderer = ({ text }) => {
    const __html = marked(text);
    return <div className="markdown" dangerouslySetInnerHTML={{ __html }} />;
};

function Message({ index, role, content, persona, editMessage }) {
    const [isEditing, setIsEditing] = useState(false);
    const [tempText, setTempText] = useState(content);

    const handleClick = () => {
        if (!isEditing) {
            setIsEditing(true);
        }
    };

    const handleBlur = () => {
        editMessage(index, tempText);
        setIsEditing(false);
    };

    const handleChange = (event) => {
        setTempText(event.target.value)
    };

    return (
        <div
            className={`${
                role === "user" ? styles.userMessage : styles.personaMessage
            } ${styles.message}`}
            style={
                role === "user"
                    ? themes[persona].userMessage
                    : themes[persona].personaMessage
            }
        >
            {isEditing ? (
                <textarea
                    className={styles.editMessage}
                    value={tempText}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    rows={content.length / 25}
                    autoFocus
                />
            ) : (
                <MarkdownRenderer text={content} />
            )}
            {!isEditing &&
            <button className={styles.editButton} onClick={handleClick}>Edit</button>
            }
        </div>
    );
}

export default Message;
