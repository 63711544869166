import React, { useState, useEffect } from "react";
import ChatLog from "./ChatLog";
import Persona from "./Persona";
import config from "./config";
import Menu from "./Menu";

import "./App.css";

import "highlight.js/styles/github.css";
import styles from "./App.module.css";

const themes = {
    asuka: {
        wallpaper: "url(/images/workshop.png)",
    },
    grog: {
        wallpaper: "url(/images/tavern.png)",
    },
    hermione: {
        wallpaper: "url(/images/kokomi_blackboard.jpg)",
    },
    custom: {
        wallpaper: "url(/images/office_happy.png)",
    },
};

const instructionText = ""

function App({ persona }) {
    const [messages, setMessages] = useState([]);
    const [mood, setMood] = useState("neutral");
    const [input, setInput] = useState("");

    useEffect(() => {
      if(persona === "custom" && messages.length === 0) {
        setInput(instructionText);
      }
    }, [persona, messages]);

    const chatLogRef = React.createRef();

    //you can't just call log(messages) after setMessages b/c it's async
    //have to use a hook for when messages are updated
    useEffect(() => {
        async function fetchData() {
            if (
                messages.length !== 0 &&
                messages.slice(-1)[0]["role"] === "user"
            ) {
                try {
                    const response = await fetch(`${config.apiUrl}/chat/${persona}`, {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify(messages),
                        mode: "cors",
                    });
                    const data = await response.json();
                    const responseMessage = data["message"];
                    const responseMood = data["mood"];
                    setMessages((prevMessages) => [
                        ...prevMessages,
                        responseMessage,
                    ]);
                    setMood(responseMood);
                } catch (error) {
                    console.error("Error:", error);
                }
            }
            document.getElementById("chat-submit-btn").disabled = false;
            document.getElementById("chat-textarea").disabled = false;
            document.getElementById("chat-textarea").focus();
        }
        fetchData();
        const chatLog = chatLogRef.current;
        chatLog.scrollTop = chatLog.scrollHeight;
    }, [messages, chatLogRef, persona]);

    const handleSubmit = async (event) => {
        event.preventDefault(); // don't refresh the page
        document.getElementById("chat-submit-btn").disabled = true;
        document.getElementById("chat-textarea").disabled = true;

        const newMessage = {
            role: "user",
            content: input,
        };

        setMessages((prevMessages) => [...prevMessages, newMessage]);

        setInput("");
    };

    const onEnterPress = (e) => {
        if (e.key === "Enter" && !e.shiftKey) {
            if (!document.getElementById("chat-submit-btn").disabled) {
                handleSubmit(e);
            }
        }
    };

    return (
        <div
            className={styles.appContainer}
            style={{ background: `${themes[persona].wallpaper} center`, backgroundSize: "auto", backgroundRepeat: "no-repeat",  display: "flex", flexDirection: "column" }}
        >
            <div className={styles.chatContainer}>
                <ChatLog chatLogRef={chatLogRef} messages={messages} persona={persona} setMessages={setMessages}/>
                <div className={styles.chatInputContainer}>
                    <form onSubmit={handleSubmit}>
                        <textarea
                            id="chat-textarea"
                            rows="4"
                            value={input}
                            onChange={(e) => setInput(e.target.value)}
                            onKeyDown={onEnterPress}
                        />

                        <button
                            className={styles.hidden}
                            id="chat-submit-btn"
                            type="submit"
                        />
                    </form>
                </div>
            </div>
        </div>
    );
}

export default App;
