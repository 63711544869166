import { Link } from "react-router-dom";

export default function Root() {
    return (
        <>
            <Link to="grog">Grog</Link>
            <br />
            <Link to="asuka">Asuka</Link>
            <br />
            <Link to="hermione">Hermione</Link>
            <br />
            <Link to="custom">Custom (create your own)</Link>
            <br />
            <a href="/README.md">README</a>
            <br />
        </>
    );
}
