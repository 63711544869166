import { saveAs } from "file-saver";
import React, { useContext } from "react";
import { Button } from "react-bootstrap";

import styles from "./Menu.module.css";

//This function takes the chatText and saves the file with the given fileName
function DownloadFile(fileName, chatText) {
    const fileTxt = new Blob([chatText], { type: "text/plain;charset=utf-8" });
    saveAs(fileTxt, fileName);
}

//This component renders the download button
function DownloadButton({ messages }) {
    const text = JSON.stringify(messages, null, 4);
    return (
        <Button
            onClick={() =>
                DownloadFile(`Chat_${new Date().getTime()}.txt`, text)
            }
        >
            Download Chat
        </Button>
    );
}

function Menu({ messages }) {
    return (
        <div className={styles.menu}>
            <DownloadButton messages={messages} />
        </div>
    );
}

export default Menu;
