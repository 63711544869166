import React from "react";

import styles from './Persona.module.css';

const imageMap = {
    anger: "/images/asuka_anger.png",
    disgust: "/images/asuka_disgust.png",
    fear: "/images/asuka_fear.png",
    joy: "/images/asuka_joy.png",
    neutral: "/images/asuka_neutral.png",
    sadness: "/images/asuka_sadness.png",
    surprise: "/images/asuka_surprise.png",
};

function Persona({ mood, persona }) {
    const imageUrl = "/images/" + persona + "/" + mood + ".png"

    return (
        <div className={styles.personaContainer}>
            <img className={styles.personaPicture} src={process.env.PUBLIC_URL + imageUrl} alt="Image" />
        </div>
    );
}

export default Persona;
