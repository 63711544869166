import React from "react";
import Message from "./Message";
import styles from "./ChatLog.module.css";

function ChatLog({ messages, chatLogRef, persona, setMessages }) {
    const editMessage = (messageIndex, editedMessageText) => {
        //TODO: use messageId, not messageIndex, & array.find()

        // Create a copy of the original message
        const updatedMessage = {
            ...messages[messageIndex],
            content: editedMessageText,
        };

        // Replace the original message with the updated one
        setMessages([
            ...messages.slice(0, messageIndex),
            updatedMessage,
            ...messages.slice(messageIndex + 1),
        ]);
    };

    return (
        <div ref={chatLogRef} className={styles.chatLogContainer}>
            {messages.map((message, index) => (
                <Message
                    key={index} // TODO: react says to NOT use the index as the key
                    index={index}
                    role={message.role}
                    content={message.content}
                    persona={persona}
                    editMessage={editMessage}
                />
            ))}
        </div>
    );
}

export default ChatLog;
